import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Typography, Button } from "@mui/material";
import { UserContext } from "../contexts/UserContext";

function Header({ handleModalOpen, openRegister }) {
	let navigate = useNavigate();

	const items = [
		{ title: "Home", navPath: "/" },
		// { title: "Episodes", navPath: "episodes" },
		{ title: "Contact", navPath: "contact" },
		{ title: "Login", navPath: "login" },
		{ title: "Register", navPath: "register" },
	];

	// const {
	// 	userState: { isLoggedIn, isAuthenticated },
	// 	userDispatch,
	// } = useContext(UserContext);

	const logout = () => {
		// userDispatch({ type: "logout" });
		// navigate("/");
	};

	return (
		<header className="header">
			<Typography variant="h1" color="main">
				The Alien Talks
			</Typography>
			<nav className="navigation">
				<ul className="header__navigation">
					{items.map((item, i) => {
						return item.title === "Contact" ? (
							<React.Fragment key={i}>
								<li className="navigation__item">
									<Link to={item.navPath}>{item.title}</Link>
								</li>
								<span>|</span>
							</React.Fragment>
						) : (
							<li key={i} className="navigation__item">
								<Link to={item.navPath}>{item.title}</Link>
							</li>
						);
					})}
					{/* <li className="navigation__item">
						<Link to="/">Home</Link>
					</li>
					<li className="navigation__item">
						<Link to="/episodes">Episodes</Link>
					</li>
					<li className="navigation__item">
						<Link to="/contact">Contact</Link>
					</li> */}

					{/* 
					{isLoggedIn && isAuthenticated ? (
						<li className="navigation__item">
							<Button onClick={logout}>Logout</Button>
						</li>
					) : (
						<>
							<li className="navigation__item">
								<Typography style={{ cursor: "pointer" }}>
									<Link to="login">Login</Link>
								</Typography>
							</li>
							<li className="navigation__item">
								<Typography style={{ cursor: "pointer" }}>
									<Link to="register">Register</Link>
								</Typography>
							</li>
						</>
					)} */}
				</ul>
			</nav>
		</header>
	);
}

export default Header;
