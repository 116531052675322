import * as React from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const MediaCard = ({ post }) => {
	return (
		<>
			<Card sx={{ maxWidth: 300 }}>
				<CardMedia
					component="img"
					height="140"
					image={post.img}
					alt="virus"
				/>
				<CardContent>
					<Typography gutterBottom variant="h5" component="div">
						{post.title}
					</Typography>
					<Typography variant="body2" color="text.secondary">
						{post.summary}
					</Typography>
				</CardContent>
				<CardActions>
					{/* <Button size="small">Share</Button> */}
					<Button component={Link} to={`posts/${post.id}`} size="small">
						Read Article
					</Button>
				</CardActions>
			</Card>
		</>
	);
};

export default MediaCard;
