import React from "react";
import {
	Route,
	createBrowserRouter,
	RouterProvider,
	createRoutesFromElements,
} from "react-router-dom";
import PrivateRoute from "./routers/PrivateRoute";
import Dashboard from "./pages/dashboard/Dashboard";
// import Landing from "./pages/home/Homepage";
import Contact from "./pages/Contact";
import Login from "./pages/Login";
import { AuthProvider } from "./contexts/authContext";
import UserProvider from "./contexts/UserContext";
import LoginModal from "./components/LoginModal";
import RegisterModal from "./components/RegisterModal";
import Layout from "./components/Layout";
import Home from "./pages/home/Home";
import Post from "./pages/post/Post";

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route>
			<Route element={<Layout />}>
				<Route path="/" element={<Home />} />
				<Route path="contact" element={<Contact />} />
				<Route path="posts/:post" element={<Post />} />
			</Route>
		</Route>
	)
);

function App() {
	// const [modalOpen, setModalOpen] = useState(false);
	// const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);

	// const handleOpen = () => setModalOpen(true);
	// const handleClose = () => setModalOpen(false);

	// const handleRegisterModalOpen = () => setIsRegisterModalOpen(true);
	// const handleRegisterModalClose = () => setIsRegisterModalOpen(false);

	return <RouterProvider router={router} />;

	// return (
	// 	<div className="App">
	// 		<UserProvider>
	// 			<AuthProvider>
	// 				{modalOpen && (
	// 					<LoginModal
	// 						modalOpen={modalOpen}
	// 						handlOpen={handleOpen}
	// 						handleClose={handleClose}
	// 					/>
	// 				)}

	// 				{isRegisterModalOpen && (
	// 					<RegisterModal
	// 						modalOpen={isRegisterModalOpen}
	// 						handleOpen={handleRegisterModalOpen}
	// 						handleClose={handleRegisterModalClose}
	// 					/>
	// 				)}

	// 				<Routes>
	// 					<Route path="/" element={<Layout />}>
	// 						{/* <Route path="/" element={<Landing />} /> */}
	// 						{/* <Route path="home" element={<Landing />} /> */}
	// 						<Route path="posts/:id" element={<Post />} />
	// 						<Route path="contact" element={<Contact />} />
	// 						<Route path="login" element={<Login />} />
	// 					</Route>

	// 					<Route
	// 						path="dashboard"
	// 						element={
	// 							<PrivateRoute>
	// 								<Dashboard />
	// 							</PrivateRoute>
	// 						}
	// 					/>
	// 				</Routes>
	// 			</AuthProvider>
	// 		</UserProvider>
	// 	</div>
	// );
}

export default App;
