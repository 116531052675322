import React, { useState } from "react";
import axios from "axios";
import { TextField, Button, Typography } from "@mui/material";

const Contact = () => {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");

	const handleInputValidation = () => {
		if (!email) return false;
		if (!message) return false;

		return true;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const isValid = handleInputValidation();

		if (isValid) {
			try {
				const config = {
					headers: {
						"Content-Type": "application/json",
					},
				};

				const payload = {
					name: name,
					email: email,
					message: message,
				};

				const { data } = await axios.post(
					"/api/communications",
					payload,
					config
				);
			} catch (err) {
				console.log(err);
			}
		}
	};

	return (
		<>
			<form onSubmit={handleSubmit} className="contact-form">
				<Typography
					style={{ textAlign: "center", position: "relative", bottom: 20 }}
					variant="h4"
					color="main"
				>
					Get In Touch
				</Typography>
				<div className="input-wrapper">
					<TextField
						style={{ width: 400 }}
						id="outlined-basic"
						label="Your Name"
						variant="outlined"
						value={name}
						onChange={(e) => setName(e.target.value)}
						size="small"
					/>
				</div>
				<div className="input-wrapper">
					<TextField
						style={{ width: 400 }}
						id="outlined-basic"
						label="Your Email"
						variant="outlined"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						size="small"
					/>
				</div>
				<div className="input-wrapper">
					<TextField
						style={{ width: 400 }}
						id="outlined-multiline-static"
						label="Message"
						multiline
						rows={4}
						variant="outlined"
						value={message}
						onChange={(e) => setMessage(e.target.value)}
					/>
				</div>
				<div>
					<Button
						style={{ width: 400 }}
						type="submit"
						variant="contained"
						size="large"
						color="primary"
					>
						Submit
					</Button>
				</div>
			</form>
		</>
	);
};

export default Contact;
