import { Typography } from "@mui/material";

const Footer = () => {
	return (
		<div className="footer">
			<div className="footer__copyright">
				<Typography style={{ fontSize: 14 }} variant="body2">
					&copy;2023 The Alien Talks, All Rights Reserved
				</Typography>
			</div>
		</div>
	);
};

export default Footer;
