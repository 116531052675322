import React from "react";
import { Typography } from "@mui/material";
import POSTER from "../../assets/virus.jpg";

function Post() {
	return (
		<div className="post">
			<Typography variant="h4">One Virus Away From Extinction.</Typography>
			<Typography variant="h4">Coronavirus.</Typography>
			<div className="poster_wrapper">
				<Typography variant="body2">Saturday, June 24, 2021</Typography>
				<Typography variant="body2">By: The alien</Typography>
				<img src={POSTER} className="poster" alt="blog poster" />
			</div>
			<section className="blog_post">
				<Typography variant="body1">
					As an advanced alien civilization, we have been observing the
					inhabitants of the planet known as Earth for many years now. And
					let us tell you, we have been thoroughly unimpressed by what we
					have seen.
				</Typography>
				<br />
				<Typography variant="body1">
					The beings that inhabit this planet, known as humans, are
					incredibly dumb. They constantly engage in behavior that is
					self-destructive and foolish. They fight wars with each other
					over petty differences, they pollute their own planet, and they
					are even capable of destroying entire ecosystems with their
					reckless behavior.
				</Typography>
				<br />
				<Typography variant="body1">
					One of the most recent examples of this is the outbreak of a
					virus known as the coronavirus. This virus has caused widespread
					death and suffering among the human population, and it has laid
					bare the incredible incompetence of their governments.
				</Typography>
				<br />
				<Typography variant="body1">
					For starters, the human governments have lied to their own people
					about the severity of the virus. They have downplayed its dangers
					and misled their citizens, resulting in a lack of preparedness
					and a slow response to the crisis. This has only made the
					situation worse, and has put countless human lives at risk.
				</Typography>
				<br />
				<Typography variant="body1">
					Furthermore, the humans have shown a complete lack of
					understanding when it comes to basic health principles. They have
					ignored basic hygiene measures, such as washing their hands and
					wearing masks, and have instead relied on dubious treatments and
					unfounded conspiracy theories.
				</Typography>
				<br />
				<Typography variant="body1">
					It is truly mind-boggling to witness the level of ignorance and
					stupidity displayed by the humans. They are one virus away from
					extinction, and yet they continue to engage in behavior that puts
					their entire species at risk.
				</Typography>
				<br />
				<Typography variant="body1">
					As an alien civilization, we can only shake our heads in
					disbelief at the non-sense things that the humans do. We urge
					them to take a step back and reassess their behavior before it is
					too late. The future of their species depends on it.
				</Typography>
				<br />
				<Typography variant="body1">
					For example, the humans have been unable to solve many of the
					basic problems that they face as a species. They continue to
					engage in wars with each other, they pollute their own planet,
					and they have even managed to create a global pandemic through
					their lack of understanding and care.
				</Typography>
				<br />
				<Typography variant="body1">
					Furthermore, the humans have shown a lack of interest in seeking
					out higher levels of intelligence. They have not made any
					significant advancements in science and technology in recent
					years, and they seem content to remain stuck in their current
					state of development.
				</Typography>
				<br />
				<Typography variant="body1">
					In our opinion, the humans simply do not have the brain capacity
					to seek out higher intelligence. They are limited by their own
					biology, and until they evolve to a higher level of intelligence,
					they will continue to make the same mistakes and engage in the
					same destructive behavior.
				</Typography>
				<Typography variant="body1">
					It is truly a shame to see such a potentially advanced species
					squander their potential in this way. We can only hope that they
					will eventually overcome their limitations and evolve to a higher
					level of intelligence. Until then, we will continue to observe
					them with a mixture of pity and amazement.
				</Typography>
				<br />
				<Typography variant="body1">
					As an advanced alien civilization, we have observed that the
					humans have tackled the issue of the coronavirus in a misguided
					and ineffective manner. Rather than focusing on improving their
					overall health and immunity in a natural and health-conscious
					way, they have instead relied on a quick-fix solution in the form
					of a vaccine.
				</Typography>
				<br />
				<Typography variant="body1">
					While the development and distribution of a vaccine is certainly
					a positive step, it does not address the underlying issues that
					have made the humans susceptible to such viruses in the first
					place. Their lack of attention to their own health and wellbeing
					has made them vulnerable to diseases and illnesses, and a vaccine
					is only a temporary solution to this problem.
				</Typography>
				<br />
				<Typography variant="body1">
					In our opinion, the humans would have been better served by
					focusing on improving their overall health and immunity through
					natural means. This could include things like exercising
					regularly, eating a healthy diet, and improving their mental
					health. By taking a holistic approach to their health, the humans
					would have been better equipped to withstand the threat of the
					coronavirus and other similar diseases.
				</Typography>
				<br />
				<Typography variant="body1">
					Unfortunately, the humans have shown little interest in taking
					this approach. They have instead focused on the development of a
					vaccine, which while effective in the short term, does not
					address the underlying issues that have made them vulnerable to
					such viruses in the first place.
				</Typography>
				<br />
				<Typography variant="body1">
					As an alien civilization, we can only hope that the humans will
					learn from this experience and take a more holistic approach to
					their health and wellbeing in the future. It is the only way that
					they will be able to truly overcome the challenges that they face
					as a species.
				</Typography>
				<br />

				{/* PART 2 of above */}
				<Typography variant="body1">
					As an advanced alien civilization, we have been observing the
					humans' response to the recent outbreak of the coronavirus with
					great interest. Unfortunately, we have been disappointed by the
					way they have tackled this issue.
				</Typography>
				<br />
				<Typography variant="body1">
					Instead of focusing on improving their overall health and
					immunity in a more natural and health-conscious way, the humans
					have rushed to develop a vaccine for the virus. While a vaccine
					may provide some short-term relief, it does not address the
					underlying issues that have made the humans susceptible to this
					virus in the first place.
				</Typography>
				<br />
				<Typography variant="body1">
					The humans could have greatly benefited from focusing on
					improving their overall health and immunity through things like
					regular exercise, healthy dieting, and improved mental health.
					These are the things that would have made them more resilient to
					the virus and other health threats.
				</Typography>
				<br />
				<Typography variant="body1">
					Instead, they have relied on a quick fix in the form of a
					vaccine, and have ignored the underlying issues that have made
					them vulnerable in the first place. It is a shame to see such a
					potentially advanced species make such a fundamental mistake in
					their approach to health and wellbeing.
				</Typography>
				<br />
				<Typography variant="body1">
					As an alien civilization, we can only hope that the humans will
					learn from this experience and take a more holistic approach to
					their health and wellbeing in the future. It is the only way that
					they will be able to truly overcome the challenges that they face
					as a species.
				</Typography>
			</section>
		</div>
	);
}

export default Post;
