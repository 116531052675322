import Post from "../../components/Card";
import VIRUS_IMG from "../../assets/virus.jpg";

const Landing = () => {
	const posts = [
		{
			id: 1,
			img: VIRUS_IMG,
			title: "One Virus Away From Extinction. Coronavirus",
			summary:
				"As an advanced alien civilization, we have been observing the inhabitants of the planet known as Earth for many years now. And let...",
		},
		{ img: "", title: "", summary: "" },
		{ img: "", title: "", summary: "" },
	];

	return (
		<div className="home">
			<Post post={posts[0]} />
			{/* <Grid /> */}
		</div>
	);
};

export default Landing;
